




























































import { Component, Prop, Vue } from 'vue-property-decorator'

import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { IModalParams, ModalStore, ModalType } from '~/store/modal'
import { UploadStore, UploadType } from '~/store/upload'
import { createProduct, updateProduct, fetchProducts } from '#/api/product'
import { UserStore } from '#/store/user'
import AlertBoxSubscription from '#/components/AlertBoxSubscription.vue'
import { ENV_IS_DEVELOPMENT, ENV_IS_STAGING } from '~/const/environment'

enum FileToRemoveOrAdd {
	Image,
	ProductFile
}

@Component({ components: { VLoadSpinner, AlertBoxSubscription } })
export default class ProductPage extends Vue {
	@Prop({ type: Number }) id: number
	FileToRemoveOrAdd = FileToRemoveOrAdd

	isLoading = true
	existsOnDatabase = false
	// @ts-ignore
	product: IProduct = {
		image_url: '',
		name: '',
		price: 0,
		download_file_name: ''
	}
	isDropdownOpen = false
	urlStart = ENV_IS_DEVELOPMENT || ENV_IS_STAGING ? 'test-elite.thecharts.com' : 'elite.thecharts.com'

	@UserStore.State('authenticatedUser') authenticatedUser: IUserProducer
	@UserStore.Getter('hasEliteSubscription') hasEliteSubscription: boolean

	get productPageLink() {
		return `https://${this.urlStart}/${this.authenticatedUser ? this.authenticatedUser.slug : ''}/product`
	}

	get productFileNameCleaned(): string {
		if (!this.product.download_file_name) return ''
		const indexOfLastSlash = this.product.download_file_name.lastIndexOf('/')
		return this.product.download_file_name.substring(indexOfLastSlash + 1)
	}

	@ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void
	@UploadStore.Action('singleFileUpload') singleFileUpload: (IClientUploadParams: IClientUploadParams) => Promise<IUploadReturn>

	closeDropdown() {
		this.isDropdownOpen = false
	}

	async _fetchProduct() {
		this.isLoading = true
		try {
			const product = await fetchProducts()
			if (product) {
				this.existsOnDatabase = product ? true : false
				this.product = product
			}
		} finally {
			this.isLoading = false
		}
	}

	async uploadFile(fileList: FileList, fileToAdd: FileToRemoveOrAdd) {
		try {
			let uploadType = UploadType.Public
			if (fileToAdd === FileToRemoveOrAdd.ProductFile) uploadType = UploadType.SoundKit

			const { url, pathToFile } = await this.singleFileUpload({ fileList, uploadType, maxPxSize: uploadType === UploadType.Public ? 500 : undefined })
			switch (fileToAdd) {
				case FileToRemoveOrAdd.Image:
					this.product.image_url = url
					break
				case FileToRemoveOrAdd.ProductFile:
					this.product.download_file_name = pathToFile
					break
			}
			// if the soundkit was already existing, update it and send api request
			if (this.existsOnDatabase) await updateProduct(this.product)
		} catch (err) {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
		}
	}

	async removeFile(fileToRemove: FileToRemoveOrAdd) {
		try {
			switch (fileToRemove) {
				case FileToRemoveOrAdd.Image:
					this.product.image_url = null
					break
				case FileToRemoveOrAdd.ProductFile:
					this.product.download_file_name = null
					break
			}
			// if the soundkit was already existing, update it and send api request
			if (this.existsOnDatabase) await updateProduct(this.product)
		} catch (error) {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
		}
	}

	// create or updates service
	async saveAllChanges() {
		try {
			if (this.existsOnDatabase) await updateProduct(this.product)
			else await createProduct(this.product)
		} catch (error) {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
		} finally {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
			this._fetchProduct()
		}
	}

	created() {
		this._fetchProduct()
		this.isLoading = false
	}
}
