import { get, post, update, remove } from '~/services/http-requests'

const _BS_PRODUCTS = 'products'

/*
 **** GET ****
 */

export async function fetchProducts(): Promise<IProduct> {
    const [first] = await get({ baseProperty: _BS_PRODUCTS })
    return first
}

/*
 **** POST ****
 */
export function createProduct(product: IProduct): Promise<IProduct> {
    return post({ baseProperty: _BS_PRODUCTS, dispatchParams: product })
}

/*
 **** PATCH ****
 */
export function updateProduct(product: Partial<IProduct>): Promise<IProduct> {
    return update({ baseProperty: _BS_PRODUCTS, fullUrl: `${_BS_PRODUCTS}/${product.id}/`, dispatchParams: product })
}
